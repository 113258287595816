import { TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';

import {
  IContactAddress,
  IContactEmail,
  IContactNumber,
  IName,
  ProposalInformation,
} from '@shared/interfaces';
import {
  extractDefaultValue,
  getArrangedName,
  thousandSeparatorAdd,
} from '@shared/utils';

import { DateTimeFormatService } from '../../../../services/date-time-format.service';
import { ClientSetupResponseDTO } from '../../../setup/general/services/client-data.service';

@Component({
  selector: 'app-print-other-proposal-component',
  templateUrl: './other-class-proposal-template.component.html',
  styleUrls: ['./other-class-proposal-template.component.scss'],
})
export class OtherClassProposalTemplateComponent {
  @Input() proposal_information: ProposalInformation;
  @Input() ref_number: string;
  @Input() client_information: ClientSetupResponseDTO;
  @Input() created_on: string;
  @Input() communication_modes: string;
  @Input() communication_languages: string;

  thousandSeparatorAdd = thousandSeparatorAdd;

  constructor(
    public dateTimeFormatService: DateTimeFormatService,
    public titleCasePipe: TitleCasePipe
  ) {}

  getFormattedDate(value: string | Date) {
    return this.dateTimeFormatService?.formatDateAsString(new Date(value));
  }

  getName(name: IName) {
    return getArrangedName(name);
  }

  getPhone(contact: IContactNumber[]) {
    return extractDefaultValue({
      type: 'PHONE',
      value: contact,
    });
  }

  getEmail(email: IContactEmail[]) {
    return extractDefaultValue({
      type: 'EMAIL',
      value: email,
    });
  }

  getAddress(address: IContactAddress[]) {
    return extractDefaultValue({
      type: 'ADDRESS',
      value: address,
    });
  }

  getStringForBoolean(value: boolean): 'YES' | 'NO' {
    return value ? 'YES' : 'NO';
  }
}
