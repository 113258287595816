<table style="width: 100%">
  <div class="container-fluid">
    <div style="float: right; margin-top: 0px; font-size: small">
      {{ this.created_on }}
    </div>
    <div class="column left" style="margin-top: -15px; margin-right: 30px">
      <img
        [src]="client_information?.logo_path_light"
        width="110px"
        height="150px"
        [alt]="client_information?.website"
      />
    </div>

    <div
      style="
        position: relative;
        align-items: left;
        justify-content: left;
        text-align: left;
      "
    >
      <div style="padding-left: 50px">
        <h2 style="padding-top: 5px">
          {{ client_information?.name?.toUpperCase() }}
        </h2>
        <p style="margin: 0; padding: 0px">
          {{ client_information?.address }}
        </p>
        <p style="margin: 0; padding: 0px">
          Tel :
          {{
            client_information?.phone_number?.dialCode +
              client_information?.phone_number?.number
          }}
        </p>
        <p style="margin: 0; padding: 0px">
          Email:
          {{ client_information?.email }}
        </p>
      </div>
      <div
        style="
          margin-top: 33px;
          justify-content: center;
          text-align: center;
          padding-right: 8px;
        "
      >
        <p style="font-size: 20px; margin-top: 2px">
          <strong>
            {{ 'policies.proposal.pdf.proposal-form' | translate }}
          </strong>
        </p>
        <p style="font-size: 18px; margin-bottom: 0px">
          <strong>
            {{ getName(proposal_information?.proposer_details?.name) }}
          </strong>
        </p>
        <p style="font-size: 16px; margin-top: 0px">
          <strong>
            {{ 'policies.proposal.pdf.request-number' | translate }}:</strong
          >
          {{ ref_number }}
        </p>
      </div>
      <div class="note-box">
        <mat-icon style="vertical-align: middle; font-size: 21px"
          >info</mat-icon
        >
        {{ 'policies.proposal.pdf.important' | translate }}:
        <p style="margin-left: 23px; text-align: justify">
          {{ 'policies.proposal.pdf.important-notice' | translate }}
        </p>
      </div>
      <div class="content" style="padding-top: -20px; margin-top: -20px">
        <br />
        <div>
          <div>
            <!------ Proposer Stepper -------->
            <ng-container>
              <p class="table_caption">
                <strong>
                  01.
                  {{ 'policies.proposal.add-edit.steppers.1' | translate }}
                </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.name'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        getName(proposal_information?.proposer_details?.name)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.engagement-pdf'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        titleCasePipe.transform(
                          proposal_information?.proposer_details?.engagement
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.contact'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                          proposal_information?.proposer_details?.contact?.[0]
                            ? getPhone(
                                proposal_information?.proposer_details?.contact
                              )
                            : '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.email'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                          proposal_information?.proposer_details?.email?.[0]
                            ? getEmail(
                                proposal_information?.proposer_details?.email
                              )
                            : '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.fax'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.proposer_details?.fax
                          ? getPhone(
                              proposal_information?.proposer_details?.fax
                            )
                          : '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.identification_number'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.proposer_details
                          ?.identification_number || '-'
                      }}
                    </td>
                  </tr>

                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.occupation'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.proposer_details?.occupation ||
                          '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.vat_number'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.proposer_details?.vat_number ||
                          '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.svat_number'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.proposer_details?.svat_number ||
                          '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.communication-mode-pdf'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{ communication_modes }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.communication-language-pdf'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{ communication_languages }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.cover-type-pdf'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        titleCasePipe.transform(
                          proposal_information?.proposer_details?.cover_type
                        ) || '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.start-date'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.proposer_details?.start_date
                          ? dateTimeFormatService.formatDateWithoutTime(
                              proposal_information?.proposer_details?.start_date
                            )
                          : ''
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.end-date'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.proposer_details?.end_date
                          ? dateTimeFormatService.formatDateWithoutTime(
                              proposal_information?.proposer_details?.end_date
                            )
                          : '-'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.proposer.type-pdf'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.proposer_details?.customer_type ||
                          '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
            </ng-container>

            <!------ Other additional feilds -------->
            <ng-container>
              <p class="table_caption">
                <strong>
                  02.
                  {{ 'policies.proposal.add-edit.steppers.9' | translate }}
                </strong>
              </p>
              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.previous-policy.expiry-date'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.previous_policies_details
                          ?.expiry_date
                          ? dateTimeFormatService.formatDateWithoutTime(
                              proposal_information?.previous_policies_details
                                ?.expiry_date
                            )
                          : '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                *ngIf="
                  proposal_information?.previous_policies_details?.policy_info
                    ?.length > 0
                "
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <ng-container
                    *ngFor="
                      let pol of proposal_information?.previous_policies_details
                        ?.policy_info
                    "
                  >
                    <tr>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.previous-policy.company_name'
                            | translate
                        }}
                      </td>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.previous-policy.proposal_policy_number'
                            | translate
                        }}
                      </td>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.previous-policy.policy_status'
                            | translate
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td width="33.3%" class="table_result">
                        {{ pol?.company_name }}
                      </td>
                      <td width="33.3%" class="table_result">
                        {{ pol.proposal_policy_number }}
                      </td>
                      <td width="33.3%" class="table_result">
                        {{ pol.policy_status }}
                      </td>
                    </tr>

                    <ng-container *ngFor="let cla of pol?.claim_info">
                      <tr>
                        <td width="33.3%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.previous-policy.year'
                              | translate
                          }}
                        </td>
                        <td width="33.3%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.previous-policy.type_of_loss'
                              | translate
                          }}
                        </td>
                        <td width="33.3%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.previous-policy.additional_info'
                              | translate
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td width="33.3%" class="table_result">
                          {{
                            dateTimeFormatService.formatDateWithoutTime(
                              cla?.year
                            )
                          }}
                        </td>
                        <td width="33.3%" class="table_result">
                          {{ cla.type_of_loss }}
                        </td>
                        <td width="33.3%" class="table_result">
                          {{ cla?.additional_info || '-' }}
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>
            </ng-container>

            <!------ Previous Policy Stepper -------->
            <ng-container>
              <p class="table_caption">
                <strong>
                  03.
                  {{ 'policies.proposal.add-edit.steppers.4' | translate }}
                </strong>
              </p>
              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.previous-policy.expiry-date'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.previous_policies_details
                          ?.expiry_date
                          ? dateTimeFormatService.formatDateWithoutTime(
                              proposal_information?.previous_policies_details
                                ?.expiry_date
                            )
                          : '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                *ngIf="
                  proposal_information?.previous_policies_details?.policy_info
                    ?.length > 0
                "
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <ng-container
                    *ngFor="
                      let pol of proposal_information?.previous_policies_details
                        ?.policy_info
                    "
                  >
                    <tr>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.previous-policy.company_name'
                            | translate
                        }}
                      </td>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.previous-policy.proposal_policy_number'
                            | translate
                        }}
                      </td>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.previous-policy.policy_status'
                            | translate
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td width="33.3%" class="table_result">
                        {{ pol?.company_name }}
                      </td>
                      <td width="33.3%" class="table_result">
                        {{ pol.proposal_policy_number }}
                      </td>
                      <td width="33.3%" class="table_result">
                        {{ pol.policy_status }}
                      </td>
                    </tr>

                    <ng-container *ngFor="let cla of pol?.claim_info">
                      <tr>
                        <td width="33.3%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.previous-policy.year'
                              | translate
                          }}
                        </td>
                        <td width="33.3%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.previous-policy.type_of_loss'
                              | translate
                          }}
                        </td>
                        <td width="33.3%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.previous-policy.additional_info'
                              | translate
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td width="33.3%" class="table_result">
                          {{
                            dateTimeFormatService.formatDateWithoutTime(
                              cla?.year
                            )
                          }}
                        </td>
                        <td width="33.3%" class="table_result">
                          {{ cla.type_of_loss }}
                        </td>
                        <td width="33.3%" class="table_result">
                          {{ cla?.additional_info || '-' }}
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>
            </ng-container>

            <!------ Discounts Stepper -------->
            <ng-container>
              <p class="table_caption">
                <strong>
                  05.
                  {{ 'policies.proposal.add-edit.steppers.6' | translate }}
                </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="70%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.discount.question_1'
                          | translate
                      }}
                    </th>
                    <td width="30%" class="table_result">
                      {{
                        proposal_information?.discounts_details?.question_1
                          ? 'Yes'
                          : 'No'
                      }}
                    </td>
                  </tr>
                  <tr
                    *ngIf="proposal_information?.discounts_details?.question_1"
                  >
                    <th width="70%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.discount.question_1_value'
                          | translate
                      }}
                    </th>
                    <td width="30%" class="table_result">
                      {{
                        proposal_information?.discounts_details
                          ?.question_1_details || '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>

            <!------ Additional Covers Stepper -------->
            <ng-container>
              <p class="table_caption">
                <strong>
                  06.
                  {{ 'policies.proposal.add-edit.steppers.7' | translate }}
                </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.additional-covers.hd1'
                          | translate
                      }}
                    </th>
                    <th
                      width="50%"
                      class="table_header"
                      style="text-align: right"
                    >
                      {{
                        'policies.proposal.add-edit.steppers.additional-covers.hd2'
                          | translate
                      }}
                    </th>
                  </tr>
                  <tr
                    *ngFor="
                      let cover of this.proposal_information
                        ?.additional_covers_details?.covers
                    "
                  >
                    <td width="50%" class="table_result">
                      {{ cover?.name }}
                    </td>
                    <td
                      width="50%"
                      class="table_result"
                      style="text-align: right"
                    >
                      {{ thousandSeparatorAdd(cover?.value) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>

            <!------ Credit Request Stepper -------->
            <ng-container>
              <p class="table_caption">
                <strong>
                  {{ '07' }}.
                  {{ 'policies.proposal.add-edit.steppers.2' | translate }}
                </strong>
              </p>

              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <tr>
                    <th width="25%" class="table_header">Payment Method</th>
                    <td width="25%" class="table_result">
                      {{
                        titleCasePipe.transform(
                          proposal_information?.credit_request_details
                            ?.payment_method
                        ) || '-'
                      }}
                    </td>
                  </tr>
                  <tr
                    *ngIf="
                      proposal_information?.credit_request_details
                        ?.payment_method === 'CREDIT'
                    "
                  >
                    <th width="25%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.credit-request.credit-amount'
                          | translate
                      }}
                    </th>
                    <td width="25%" class="table_result">
                      {{
                        proposal_information?.credit_request_details
                          ?.credit_amount || '-'
                      }}
                      Days
                    </td>
                  </tr>
                  <tr
                    *ngIf="
                      proposal_information?.credit_request_details
                        ?.payment_method === 'CREDIT'
                    "
                  >
                    <th width="25%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.credit-request.credit-period'
                          | translate
                      }}
                    </th>
                    <td width="25%" class="table_result">
                      {{
                        proposal_information?.credit_request_details
                          ?.credit_period || '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                *ngIf="
                  proposal_information?.credit_request_details?.schedules
                    ?.length > 0
                "
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
              >
                <tbody>
                  <ng-container
                    *ngFor="
                      let sch of proposal_information?.credit_request_details
                        ?.schedules
                    "
                  >
                    <tr>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.credit-request.date'
                            | translate
                        }}
                      </td>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.credit-request.days'
                            | translate
                        }}
                      </td>
                      <td width="33.3%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.credit-request.amount'
                            | translate
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td width="33.3%" class="table_result">
                        {{
                          sch.date
                            ? dateTimeFormatService.formatDateWithoutTime(
                                sch.date
                              )
                            : '-'
                        }}
                      </td>
                      <td width="33.3%" class="table_result">
                        {{ sch.number_of_days }}
                      </td>
                      <td width="33.3%" class="table_result">
                        {{ sch.amount }}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <table
                width="100%"
                border="1"
                style="border-collapse: collapse; text-align: left"
                *ngIf="
                  proposal_information?.credit_request_details
                    ?.payment_method === 'CREDIT'
                "
              >
                <tbody>
                  <tr>
                    <th width="50%" class="table_header">
                      {{
                        'policies.proposal.add-edit.steppers.credit-request.remark'
                          | translate
                      }}
                    </th>
                    <td width="50%" class="table_result">
                      {{
                        proposal_information?.credit_request_details?.remark ||
                          '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </div>
      </div>
      <div style="padding-left: 10px">
        <p class="table_caption">
          <strong>
            {{ '08' }}.
            {{ 'policies.proposal.pdf.declaration' | translate }}</strong
          >
        </p>
        <div style="margin: auto; padding-top: 10px; text-align: justify">
          I,
          {{ getName(proposal_information?.proposer_details?.name) }}
          {{ 'policies.proposal.pdf.declaration-part1' | translate
          }}<br /><br />

          {{ 'policies.proposal.pdf.declaration-part2-1' | translate }}
          {{ client_information?.name }}
          {{ 'policies.proposal.pdf.declaration-part2-2' | translate }}
        </div>
      </div>
      <br />
      <div>
        <p class="table_caption">
          <strong>
            {{
              'policies.proposal.add-edit.steppers.proposer.name' | translate
            }}:
          </strong>
        </p>
        <table
          width="100%"
          border="1"
          style="border-collapse: collapse; text-align: center"
        >
          <tbody>
            <tr height="80px">
              <th width="50%" class="table_result"></th>
              <td width="50%" class="table_result"></td>
            </tr>
            <tr>
              <td width="50%" class="table_result">
                {{ 'policies.proposal.pdf.sign' | translate }}
              </td>
              <td width="50%" class="table_result">
                {{ 'policies.proposal.pdf.date' | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <div>
        <table
          width="100%"
          border="1"
          style="border-collapse: collapse; text-align: center"
        >
          <tbody>
            <tr height="100px">
              <th width="33.3%" class="table_result"></th>
              <td width="33.3%" class="table_result"></td>
              <td width="33.3%" class="table_result"></td>
            </tr>
            <tr>
              <td width="33.3%" class="table_result">
                {{ 'policies.proposal.pdf.officer-sign' | translate }}
              </td>
              <td width="33.3%" class="table_result">
                {{ 'policies.proposal.pdf.date' | translate }}
              </td>
              <td width="33.3%" class="table_result">
                {{ 'policies.proposal.pdf.rubber-stamp' | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="footer"
        style="justify-content: center; text-align: center; margin-left: -10px"
      ></div>
    </div>
  </div>
</table>
