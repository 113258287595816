// ------------------------------------------------------------------------------
// ---------------------- BOMS RELATED ENUMS -------------------------------

export enum BOMS_REFERENCE_CATEGORIES_LIST {
  CLASSES = 'Classes',
  COMMISSIONS = 'Commissions',
  TYPES = 'Types',
  SUB_CLASSES = 'Sub-Classes',
  COVERS_MTR = 'Covers (MTR)',
  COVERS_MED = 'Covers (MED)',
  COVERS_TRV = 'Covers (TRV)',
  COVERS_OTR = 'Covers (OTR)',
  SUBLIMITS_MTR = 'Sublimits (MTR)',
  SUBLIMITS_MED = 'Sublimits (MED)',
  SUBLIMITS_TRV = 'Sublimits (TRV)',
  SUBLIMITS_OTR = 'SUBLIMITS (OTR)',
  DISCOUNTS = 'Discounts',
  VEHICLE_MAKES = 'Vehicle Makes',
  VEHICLE_MODELS = 'Vehicle Models',
  VEHICLE_TYPES = 'Vehicle Types',
  CHECKLIST_DOCUMENTS_MTR = 'Document Types (MTR)',
  CHECKLIST_DOCUMENTS_MED = 'Document Types (MED)',
  FUEL_TYPES = 'Fuel Types',
  NATURE = 'Natures',
  COVERAGES = 'Coverages',
  COVERAGE_TYPES = 'Coverage Types',
  COMPANY_NAMES = 'Company Names',
  VEHICLE_REGISTRATION_TYPES = 'Vehicle Registration Types',
  OCCUPATION = 'Occupations',
  BANKS = 'Banks',
  BRANCHES = 'Branches',
  BANK_TRANSFER_TYPE = 'Bank Transfer Types',
  MED_RISK_LOADING = 'Risk Loadings (MED)',
  PLANS = 'Plans',
  SECTIONS = 'Sections',
  COUNTRIES = 'Countries',
  BRANCHES_FOR_RECRUITMENT = 'Branches for Recruitment',
}

export enum TRAVEL_PLANS_FIELDS {
  CODE = 'CODE',
  PLAN = 'PLAN',
  NATURE = 'NATURE',
}

export enum TRAVEL_SECTIONS_FIELDS {
  CODE = 'CODE',
  SECTION = 'SECTION',
  PLAN = 'PLAN',
  NATURE = 'NATURE',
}

export enum COUNTRIES_FIELDS {
  CODE = 'CODE',
  COUNTRY = 'COUNTRY',
}

export enum BANKS_FIELDS {
  CODE = 'CODE',
  NAME = 'BANK',
  BANK_CODE = 'BANK_CODE',
}

export enum FUEL_FIELDS {
  'TYPE' = 'TYPE',
}

export enum BRANCHES_FIELDS {
  BANK_CODE = 'BANK CODE',
  BRANCH_CODE = 'CODE',
  BRANCH = 'BRANCH',
}

export enum OCCUPATION_FIELDS {
  OCCUPATION_CODE = 'CODE',
  OCCUPATION = 'OCCUPATION',
}

export enum COMPANY_NAME_FIELDS {
  CODE = 'CODE',
  NAME = 'NAME',
}

export enum VEHICLE_MAKES_FIELDS {
  CODE = 'CODE',
  NAME = 'MAKE',
}

export enum VEHICLE_MODELS_FIELDS {
  CODE = 'CODE',
  MAKE = 'MAKE',
  MODEL = 'MODEL',
}

export enum VEHICLE_TYPES_FIELDS {
  CODE = 'CODE',
  TYPE = 'TYPE',
}

export enum TYPES_FIELDS {
  CODE = 'CODE',
  TYPE = 'TYPE',
}

export enum LOADING_FIELDS_TYPES {
  CODE = 'CODE',
  LOADING = 'LOADING',
  DESCRIPTION = 'DESCRIPTION',
}

export enum NATURES_FIELDS {
  CODE = 'CODE',
  NATURE = 'NATURE',
  CLASS = 'CLASS',
}

export enum PRODUCT_CLASS_CODES {
  MED = 'MED',
  MTR = 'MTR',
  TRV = 'TRV',
  FRE = 'FRE',
  MAR = 'MRN',
  MIS = 'MIS',
}

export enum CLASSES_FIELDS {
  CODE = 'CODE',
  CLASS = 'CLASS',
  DESCRIPTION = 'DESCRIPTION',
  TYPE = 'TYPE',
}

export enum COMMISSIONS_FIELDS {
  CODE = 'CODE',
  COMMISSION = 'COMMISSION',
  CLASS = 'CLASS',
  IS_DEFAULT = 'ISDEFAULT',
}

export enum SUB_CLASSES_FIELDS {
  CODE = 'CODE',
  USAGE = 'USAGE',
  CLASS = 'CLASS',
  SUB_CLASS = 'SUB-CLASS',
}

export enum SUBLIMITS_FIELDS {
  CODE = 'CODE',
  SUBLIMIT = 'SUBLIMIT',
  EFFECTIVE_FROM = 'EFFECTIVE FROM',
  EFFECTIVE_TO = 'EFFECTIVE TO',
  IS_DEFAULT = 'ISDEFAULT',
  PARENT_COVER = 'PARENT COVER',
}

export enum DOCUMENTS_FIELDS {
  TYPE = 'DOCUMENT NAME',
}

export enum COVERS_FIELDS {
  CODE = 'CODE',
  COVER = 'COVER',
  COVERAGE = 'COVERAGE',
  CLAUSE = 'CLAUSE',
  CLAUSE_NUMBER = 'CLAUSE NUMBER',
  DESCRIPTION = 'DESCRIPTION',
  EFFECTIVE_FROM = 'EFFECTIVE FROM',
  EFFECTIVE_TO = 'EFFECTIVE TO',
  IS_DEFAULT = 'ISDEFAULT',
  CLASS = 'CLASS',
  FORMULAE = 'FORMULAE',
  // ONLY IN TRAVELS
  PLAN = 'PLAN',
}

export enum COVERAGE_TYPES_FIELDS {
  NAME = 'NAME',
}

export enum COVERAGES_FIELDS {
  CODE = 'CODE',
  COVERAGE_TYPE = 'COVERAGE TYPE',
  CLASS = 'CLASS TYPE',
  COVERAGE = 'COVERAGE',
}

export enum DISCOUNTS_FIELDS {
  CODE = 'CODE',
  DISCOUNT = 'DISCOUNT',
  CLASS = 'CLASS',
  START_DATE = 'START DATE',
  END_DATE = 'END_DATE',
  IS_ENABLE = 'ISENABLE',
}

export enum BANK_TRANSFER_FIELDS {
  CODE = 'CODE',
  TRANSFER_TYPE = 'TRANSFER TYPE',
}

export enum TRAVEL_VALUE_TYPE {
  SUM_ASSURED = 'Sum Assured',
  PER_SIX_HOURS = 'Per 6 Hours',
  PER_ONE_HOURS = 'Per 1 Hours',
  PER_TWELVE_HOURS = 'Per 12 Hours',
  PER_COVERAGE = 'Per Coverage',
}

export enum TRAVEL_COVERAGE {
  ADULT = 'Adult',
  SENIOR_CITIZEN = 'Senior Citizen',
  CHILD = 'Child',
}

export enum TRAVEL_SECTION_FIELDS {
  SECTION = 'SECTION',
  PLAN = 'PLAN',
  CODE = 'CODE',
}

export enum OTHER_CLASS_SUBLIMIT_TYPE {
  EXTERNAL_REQUEST = 'External (Request)',
  EXTERNAL_UW = 'External (UW)',
  DIRECT = 'Direct',
}

export enum WORKFLOW_DATA_GET_TYPES {
  INTERNAL = 'INTERNAL',
  EXTERNAL_REQUEST = 'EXTERNAL_REQUEST',
  EXTERNAL_UW = 'EXTERNAL_UW',
}
