export enum POLICY_CLASSES {
  MOTOR = 'MOTOR',
  FIRE = 'FIRE',
  MEDICAL = 'MEDICAL',
  TRAVEL = 'TRAVEL',
  MARINE = 'MARINE',
  MISCELLANEOUS = 'MISCELLANEOUS',
  PACKAGES = 'PACKAGES',
}

export enum ENGAGEMENT_TYPES {
  DIGITAL = 'DIGITAL',
  TRADITIONAL = 'TRADITIONAL',
}

export enum CUSTOMER_TYPES {
  INDIVIDUAL = 'INDIVIDUAL',
  FLEET = 'FLEET',
  ORGANIZATION = 'ORGANIZATION',
  GROUP = 'GROUP',
}

export enum POLICY_TYPES {
  GENERAL = 'GENERAL',
  LIFE = 'LIFE',
}

export enum PAYMENT_METHODS {
  CREDIT = 'CREDIT',
  NON_CREDIT = 'NON CREDIT',

  // Non Credit types
  CASH = 'CASH',
  BANK_TRANSFERS = 'BANK TRANSFERS',
  CHEQUE = 'CHEQUE',
}

export enum POLICY_FORM_METHODS {
  QUOTATION = 'QUOTATION',
  QUICK_QUOTE = 'QUICK_QUOTE',
  PROPOSAL = 'PROPOSAL',
}

export enum POLICY_STATUS {
  AUTHORIZATION_PENDING = 'AUTHORIZATION PENDING',
  AUTHORIZATION_PENDING_POSTPONED = 'AUTHORIZATION PENDING POSTPONED',
  QUOTATION_ISSUED = 'QUOTATION ISSUED',
  QUOTATION_PROCESSING = 'QUOTATION PROCESSING',
  PROPOSAL_DRAFTED = 'PROPOSAL DRAFTED',
  PROPOSAL_SUBMITTED = 'PROPOSAL SUBMITTED',
  PROPOSAL_RETURNED = 'PROPOSAL RETURNED',
  INVOICE_GENERATED = 'INVOICE GENERATED',
  PROPOSAL_AUTHORIZED = 'PROPOSAL AUTHORIZED',
  PROPOSAL_AUTHORIZATION_PENDING = 'PROPOSAL AUTHORIZATION PENDING',
  PROPOSAL_AUTHORIZATION_PENDING_POSTPONED = 'PROPOSAL AUTHORIZATION PENDING POSTPONED',
  PENDING_PAYMENT = 'PENDING PAYMENT',
  QUICK_QUOTE = 'QUICK_QUOTE',
  RETURNED_TO_AGENT = 'RETURNED TO AGENT',
  RETURNED_TO_UNDERWRITER = 'RETURNED TO UNDERWRITER',
  REJECTED = 'REJECTED',
  PENDING_SUPERVISOR_APPROVAL = 'PENDING SUPERVISOR APPROVAL',
  PROPOSAL_PENDING_SUPERVISOR_APPROVAL = 'PROPOSAL PENDING SUPERVISOR APPROVAL',
  APPROVED = 'APPROVED',
  PROPOSAL_CANCELED = 'PROPOSAL CANCELED',
  PROPOSAL_RESUBMITTED = 'PROPOSAL RESUBMITTED',
  AMENDMENT_REQUESTED = 'AMENDMENT REQUESTED',
  CANCELATION_REQUESTED = 'CANCELATION REQUESTED',
  POLICY_CANCELATION_PENDING = 'POLICY CANCELATION PENDING',
  POLICY_ISSUED = 'POLICY ISSUED',
  POLICY_CANCELLED = 'POLICY CANCELLED',
  POLICY_CANCELATION_REQUEST_APPROVED = 'POLICY CANCELATION REQUEST APPROVED',
  POLICY_CANCELATION_PENDING_SUPERVISOR_APPROVAL = 'POLICY CANCELATION PENDING SUPERVISOR APPROVAL',
  POLICY_CANCELATION_RETURNED_TO_UNDERWRITER = 'POLICY CANCELATION RETURNED TO UNDERWRITER',
  POLICY_ISSUED_ACTIVATION_PENDING = 'POLICY ISSUED (ACTIVATION PENDING)',
  EXPIRED = 'EXPIRED',
  RENEWAL_REQUESTED = 'RENEWAL REQUESTED',
  RENEWAL_PROCESSING = 'RENEWAL PROCESSING',
  RENEWED = 'RENEWED',
}

export enum POLICY_SUB_STATUS {
  RAISED_REQUIREMENTS = 'RAISED REQUIREMENTS',
  COVER_NOTE_REQUESTED = 'COVER NOTE REQUESTED',
  COVER_NOTE_ISSUED = 'COVER NOTE ISSUED',
  COVER_NOTE_CANCELLED = 'COVER NOTE CANCELLED',
  COVER_NOTE_EXPIRED = 'COVER NOTE EXPIRED',
}
export enum CALCULATION_METHOD {
  PRO_RATA = 'PRO_RATA',
  SHORT_TERM = 'SHORT_TERM',
  ZERO_REFUND = 'ZERO_REFUND',
  INCEPTION = 'INCEPTION',
}
export enum POLICY_QUOTATION_FLOW {
  STANDARD = 'STANDARD',
  NON_STANDARD = 'NON-STANDARD',
}

export enum COMMENT_TYPES {
  INTERNAL = 'INTERNAL',
  RAISE_REQUIREMENTS = 'RAISE_REQUIREMENTS',
  RETURN_TO_AGENT = 'RETURN_TO_AGENT',
  REFER_TO_REINSURER = 'REFER_TO_REINSURER',
  RETURN_TO_UNDERWRITER = 'RETURN_TO_UNDERWRITER',
  APPROVE = 'APPROVE',
  APPROVE_AND_ASSIGN_ANOTHER = 'APPROVE_AND_ASSIGN_ANOTHER',
  POSTPONED = 'POSTPONED',
  REJECT = 'REJECT',
}

export enum AMENDMENT_TYPES {
  REQUEST_CHANGES = 'REQUEST CHANGES',
  AMEND_AS_NEW = 'AMEND AS NEW',
}

export enum TEMPLATES_DEFAULT_INPUT_VALUES { // Make sure these are equal to BOMS side
  QUOTATION_ISSUED_DATE_AND_TIME = 'Quotation Issued Date and Time',
  QUOTATION_ISSUED_BY_USER = 'Quotation Issued By User',
  BROKER = 'Broker',
  PREMIUMS_TABLE = 'Premiums Table',
  QUOTATION_NUMBER = 'Quotation Number',
  NAME_OF_PROPOSER = 'Name of Proposer',
  CURRENCY_CODE = 'Currency Code',
  CLAUSES = 'Clauses',
  FINANCIAL_INSTITUTION = 'Financial Institution',
  PERIOD_OF_COVER = 'Period Of Cover',
  SUM_INSURED = 'Sum Insured',
  CUSTOMER_NAME = 'Customer Name',
  BRANCH = 'Branch',
  AGENT_CONTACT_NUMBER = 'Agent Contact Number',
  CUSTOMER_ADDRESS = 'Customer Address',
  QUICK_QUOTE_NUMBER = 'Quick Quote Number',
  ISSUED_DATE = 'Issued Date',
  CATEGORY = 'Category',
  PRODUCT_TYPE = 'Product Type',

  // For Travel
  TRAVELER_NAME = 'Name of Traveler',
  DATE_OF_BIRTH = 'Date of Birth',
  TRAVEL_TYPE = 'Travel Type',
  NATURE_OF_TRIP = 'Nature of Trip',
  TRAVEL_AREA = 'Area of Travel',
  TRAVEL_PERIOD = 'Period of Travel',
  PRODUCT = 'Product',
  PLAN = 'Plan',
  TOTAL_PREMIUM = 'Total Premium',
  GROUP_TRAVELERS_DETAILS = 'Group Travelers Details',
  SCHEDULE_CURRENCY = 'Schedule Currency',
}

export enum TEMPLATES_DEFAULT_OUTPUT_VALUES { // Make sure these are equal to BOMS side
  CUSTOMER_NAME = 'Customer Name',
  AGE = 'Age',
  PHONE_NUMBER = 'Phone Number',
}

export enum MEDICAL_MEMBER_STATUS {
  AGE_ABOVE_65 = 'AGE ABOVE 65',
  VERIFIED = 'VERIFIED',
}
export enum SELECTED_ACTIONS {
  REQUEST_AMENDMENT = 'REQUEST_AMENDMENT',
  AMEND_NEW = 'AMEND NEW',
  AMEND_CHANGE = 'AMEND_CHANGE',
  VIEW_QUOTATION_REQUEST = 'VIEW QUOTATION REQUEST',
  ISSUE_QUOTATION = 'ISSUE QUOTATION',
}

export enum PREMIUM_CALCULATION_METHODS {
  PRO_RATE_BASIS = 'PRO RATE BASIS',
  INSPECTION = 'INSPECTION',
  SHORT_TERM_BASIS = 'SHORT TERM BASIS',
}

export enum TRAVEL_TRIP_TYPES {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export enum RENEWAL_PROCESS_TYPE {
  WITHOUT_CHANGES = 'Without Changes',
  WITH_CHANGES = 'With Changes',
}
