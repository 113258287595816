<div *ngIf="loading" class="loading-overlay">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>
<div class="container-fluid">
  <table
    style="width: 100%"
    *ngIf="proposal_information?.policy_class === 'MOTOR'"
  >
    <div class="container-fluid">
      <div style="float: right; margin-top: 0px; font-size: small">
        {{ this.created_on }}
      </div>
      <div class="column left" style="margin-top: -15px; margin-right: 30px">
        <img
          [src]="client_information?.logo_path_light"
          width="110px"
          height="150px"
          [alt]="client_information?.website"
        />
      </div>

      <div
        style="
          position: relative;
          align-items: left;
          justify-content: left;
          text-align: left;
        "
      >
        <div style="padding-left: 50px">
          <h2 style="padding-top: 5px">
            {{ client_information?.name?.toUpperCase() }}
          </h2>
          <p style="margin: 0; padding: 0px">
            {{ client_information?.address }}
          </p>
          <p style="margin: 0; padding: 0px">
            Tel :
            {{
              client_information?.phone_number?.dialCode +
                client_information?.phone_number?.number
            }}
          </p>
          <p style="margin: 0; padding: 0px">
            Email:
            {{ client_information?.email }}
          </p>
        </div>
        <div
          style="
            margin-top: 33px;
            justify-content: center;
            text-align: center;
            padding-right: 8px;
          "
        >
          <p style="font-size: 20px; margin-top: 2px">
            <strong>
              {{ 'policies.proposal.pdf.proposal-form' | translate }}
            </strong>
          </p>
          <p style="font-size: 18px; margin-bottom: 0px">
            <strong>
              {{ getName(proposal_information?.proposer_details?.name) }}
            </strong>
          </p>
          <p style="font-size: 16px; margin-top: 0px">
            <strong>
              {{ 'policies.proposal.pdf.request-number' | translate }}:</strong
            >
            {{ ref_number }}
          </p>
        </div>
        <div class="note-box">
          <mat-icon style="vertical-align: middle; font-size: 21px"
            >info</mat-icon
          >
          {{ 'policies.proposal.pdf.important' | translate }}:
          <p style="margin-left: 23px; text-align: justify">
            {{ 'policies.proposal.pdf.important-notice' | translate }}
          </p>
        </div>
        <div class="content" style="padding-top: -20px; margin-top: -20px">
          <br />
          <div>
            <div>
              <!------ Proposer Stepper -------->
              <ng-container>
                <p class="table_caption">
                  <strong>
                    01.
                    {{ 'policies.proposal.add-edit.steppers.1' | translate }}
                  </strong>
                </p>

                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.name'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          getName(proposal_information?.proposer_details?.name)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.engagement-pdf'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          titleCasePipe.transform(
                            proposal_information?.proposer_details?.engagement
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.contact'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.proposer_details?.contact?.[0]
                            ? getPhone(
                                proposal_information?.proposer_details?.contact
                              )
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.email'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.proposer_details?.email?.[0]
                            ? getEmail(
                                proposal_information?.proposer_details?.email
                              )
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.fax'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.proposer_details?.fax
                            ? getPhone(
                                proposal_information?.proposer_details?.fax
                              )
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.identification_number'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.proposer_details
                            ?.identification_number || '-'
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.occupation'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.proposer_details?.occupation ||
                            '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.vat_number'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.proposer_details?.vat_number ||
                            '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.svat_number'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.proposer_details?.svat_number ||
                            '-'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.communication-mode-pdf'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{ communication_modes }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.communication-language-pdf'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{ communication_languages }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.cover-type-pdf'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          titleCasePipe.transform(
                            proposal_information?.proposer_details?.cover_type
                          ) || '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.start-date'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.proposer_details?.start_date
                            ? dateTimeFormatService.formatDateWithoutTime(
                                proposal_information?.proposer_details
                                  ?.start_date
                              )
                            : ''
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.end-date'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.proposer_details?.end_date
                            ? dateTimeFormatService.formatDateWithoutTime(
                                proposal_information?.proposer_details?.end_date
                              )
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.type-pdf'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.proposer_details
                            ?.customer_type || '-'
                        }}
                      </td>
                    </tr>
                    <!-- <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.proposer.organization'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.proposer_details?.address?.[0]
                            ? getAddress(
                                proposal_information?.proposer_details
                                  ?.address
                              )
                            : '-'
                        }}
                      </td>
                    </tr> -->
                  </tbody>
                </table>
                <br />
              </ng-container>

              <!------ MOTOR FLEET related Stepper -------->

              <ng-container *ngIf="isMotorFleet">
                <p class="table_caption">
                  <strong>
                    02.
                    {{ 'policies.proposal.add-edit.steppers.3' | translate }}
                  </strong>
                </p>

                <ng-container
                  *ngFor="
                    let scheme of motor_fleet_vehicle_information;
                    let i = index
                  "
                >
                  <table
                    width="100%"
                    border="1"
                    style="border-collapse: collapse; text-align: left"
                  >
                    <tbody>
                      <tr>
                        <th width="50%" class="table_header">Scheme</th>
                        <td width="50%" class="table_result">
                          {{ scheme?.schemeName || '-' }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />

                  <ng-container
                    *ngFor="
                      let vehicle of scheme?.vehicleDetails;
                      let j = index
                    "
                  >
                    <!-- Add Vehicle Information here -->
                    <table
                      width="100%"
                      border="1"
                      style="border-collapse: collapse; text-align: left"
                    >
                      <tbody>
                        <tr>
                          <th width="25%" class="table_header">
                            Vehicle Number
                          </th>
                          <td width="25%" class="table_result">
                            {{ vehicle?.vehicleNumber || '-' }}
                          </td>
                          <th width="25%" class="table_header">Registration</th>
                          <td width="25%" class="table_result">
                            {{ vehicle?.registrationType || '-' }}
                          </td>
                        </tr>
                        <tr>
                          <th width="25%" class="table_header">
                            First Registration Date
                          </th>
                          <td width="25%" class="table_result">
                            {{ '-' }}
                          </td>
                          <th width="25%" class="table_header">Vehicle Type</th>
                          <td width="25%" class="table_result">
                            {{
                              getVehicleTypeOfFleet(vehicle?.vehicleType) || '-'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th width="25%" class="table_header">Make</th>
                          <td width="25%" class="table_result">
                            {{
                              getVehicleMakeOfFleet(vehicle?.vehicleMake) || '-'
                            }}
                          </td>
                          <th width="25%" class="table_header">Model</th>
                          <td width="25%" class="table_result">
                            {{
                              getVehicleModelOfFleet(vehicle?.vehicleModel) ||
                                '-'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th width="25%" class="table_header">Type of Body</th>
                          <td width="25%" class="table_result">
                            {{ '-' }}
                          </td>
                          <th width="25%" class="table_header">
                            Manufactured Year
                          </th>
                          <td width="25%" class="table_result">
                            {{
                              vehicle?.manufactureYear
                                ? dateTimeFormatService.formatDateWithoutTime(
                                    vehicle?.manufactureYear
                                  )
                                : '-'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th width="25%" class="table_header">Fuel Type</th>
                          <td width="25%" class="table_result">
                            {{
                              getVehicleFuelOfFleet(vehicle?.fuelType) || '-'
                            }}
                          </td>
                          <th width="25%" class="table_header">
                            Cubic Capacity (CC)
                          </th>
                          <td width="25%" class="table_result">
                            {{ vehicle?.cubicCapacity || '-' }}
                          </td>
                        </tr>
                        <tr>
                          <th width="25%" class="table_header">
                            Seating Capacity
                          </th>
                          <td width="25%" class="table_result">
                            {{ vehicle?.seatingCapacity || '-' }}
                          </td>
                          <th width="25%" class="table_header">Chassis No</th>
                          <td width="25%" class="table_result">
                            {{ vehicle?.chassisNumber || '-' }}
                          </td>
                        </tr>
                        <tr>
                          <th width="25%" class="table_header">Engine No</th>
                          <td width="25%" class="table_result">
                            {{ vehicle?.engineNumber || '-' }}
                          </td>
                          <th width="25%" class="table_header">
                            Vehicle Repair Option
                          </th>
                          <td width="25%" class="table_result">
                            {{ vehicle?.repairOption || '-' }}
                          </td>
                        </tr>
                        <tr>
                          <th width="25%" class="table_header">
                            Estimated Value (LKR)
                          </th>
                          <td width="25%" class="table_result">
                            {{ vehicle?.marketValue || '-' }}
                          </td>
                          <th width="25%" class="table_header">
                            Registered Owner/ Company Name
                          </th>
                          <td width="25%" class="table_result">
                            {{ '-' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      width="100%"
                      border="1"
                      style="border-collapse: collapse; text-align: left"
                    >
                      <tbody>
                        <tr>
                          <th width="50%" class="table_header">
                            Use Of Your Vehicle
                          </th>
                          <td width="50%" class="table_result">
                            {{
                              titleCasePipe.transform(
                                vehicle?.use_of_vehicle
                              ) || '-'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%" class="table_header">
                            Purpose Of Use
                          </th>
                          <td width="50%" class="table_result">
                            {{ getVehicleUseListOfFleet(i, j) }}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%" class="table_header">
                            Financial interest on your Vehicle
                          </th>
                          <td width="50%" class="table_result">
                            {{
                              titleCasePipe.transform(
                                vehicle?.financial_interest
                              ) || '-'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%" class="table_header">
                            Name of Firm/ Individual having financial interest
                            on this vehicle
                          </th>
                          <td width="50%" class="table_result">
                            {{ vehicle?.financial_interest_person_name || '-' }}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%" class="table_header">
                            Address of Firm/ Individual having financial
                            interest on this vehicle
                          </th>
                          <td width="50%" class="table_result">
                            {{
                              vehicle?.financial_interest_person_address || '-'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%" class="table_header">
                            Do you wish to bear a Voluntary Excess on your claim
                            ?
                          </th>
                          <td width="50%" class="table_result">
                            {{ '-' }}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%" class="table_header">Value (LKR)</th>
                          <td width="50%" class="table_result">
                            {{ vehicle?.marketValue || '-' }}
                          </td>
                        </tr>
                        <!-- <tr>
                          <th width="50%" class="table_header">
                            Is this the only vehicle you own and insure with us
                            ? ( If "No", please give details on separate sheet
                            of all vehicles owned by you with vehicle numbers
                            and insurer. )
                          </th>
                          <td width="50%" class="table_result">
                            {{ vehicle?.schemeName || '-' }}
                          </td>
                        </tr> -->
                        <tr>
                          <th width="50%" class="table_header">Product</th>
                          <td width="50%" class="table_result">
                            {{ vehicle?.product_code || '-' }}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%" class="table_header">
                            Total Annual Premium
                          </th>
                          <td width="50%" class="table_result">
                            {{ '-' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                  </ng-container>
                  <br />
                </ng-container>
              </ng-container>

              <!------ Vehicle Stepper -------->
              <ng-container *ngIf="!isMotorFleet">
                <p class="table_caption">
                  <strong>
                    02.
                    {{ 'policies.proposal.add-edit.steppers.3' | translate }}
                  </strong>
                </p>

                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.market_value'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details?.market_value ||
                            '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.registration-pdf'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details?.is_registered
                            ? 'Registered'
                            : 'Unregistered'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.vehicle_number'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details
                            ?.vehicle_number || '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.first_registration_date'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details?.is_registered
                            ? dateTimeFormatService.formatDateWithoutTime(
                                proposal_information?.vehicle_details
                                  ?.first_registration_date
                              )
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.type'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{ titleCasePipe.transform(type) || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.make'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{ make || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.model'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{ model || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.type_of_body'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details?.type_of_body ||
                            '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.manufactured_year'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details
                            ?.manufactured_year
                            ? dateTimeFormatService.formatDateWithoutTime(
                                proposal_information?.vehicle_details
                                  ?.manufactured_year
                              )
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.fuel_type'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{ fuel || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.cubic_capacity'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details
                            ?.cubic_capacity || '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.seating_capacity'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details
                            ?.seating_capacity || '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.chassis_number'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details
                            ?.chassis_number || '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.engine_number'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details
                            ?.engine_number || '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.repair_option'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details
                            ?.repair_option || '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.registered_owner'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details
                            ?.registered_owner || '-'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />

                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.use_of_vehicle'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          titleCasePipe.transform(
                            proposal_information?.vehicle_details
                              ?.use_of_vehicle
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.use-purpose'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{ vehicle_use }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.financial_interest'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          titleCasePipe.transform(
                            proposal_information?.vehicle_details
                              ?.financial_interest
                          ) || '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.financial_interest_person_name'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details
                            ?.financial_interest_person_name || '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.vehicle.financial_interest_person_address'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.vehicle_details
                            ?.financial_interest_person_address || '-'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>

              <!------ Previous Policy Stepper -------->
              <ng-container *ngIf="!isMotorFleet">
                <p class="table_caption">
                  <strong>
                    03.
                    {{ 'policies.proposal.add-edit.steppers.4' | translate }}
                  </strong>
                </p>
                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.previous-policy.expiry-date'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.previous_policies_details
                            ?.expiry_date
                            ? dateTimeFormatService.formatDateWithoutTime(
                                proposal_information?.previous_policies_details
                                  ?.expiry_date
                              )
                            : '-'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  *ngIf="
                    proposal_information?.previous_policies_details?.policy_info
                      ?.length > 0
                  "
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <ng-container
                      *ngFor="
                        let pol of proposal_information
                          ?.previous_policies_details?.policy_info
                      "
                    >
                      <tr>
                        <td width="33.3%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.previous-policy.company_name'
                              | translate
                          }}
                        </td>
                        <td width="33.3%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.previous-policy.proposal_policy_number'
                              | translate
                          }}
                        </td>
                        <td width="33.3%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.previous-policy.policy_status'
                              | translate
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td width="33.3%" class="table_result">
                          {{ pol?.company_name }}
                        </td>
                        <td width="33.3%" class="table_result">
                          {{ pol.proposal_policy_number }}
                        </td>
                        <td width="33.3%" class="table_result">
                          {{ pol.policy_status }}
                        </td>
                      </tr>

                      <ng-container *ngFor="let cla of pol?.claim_info">
                        <tr>
                          <td width="33.3%" class="table_header">
                            {{
                              'policies.proposal.add-edit.steppers.previous-policy.year'
                                | translate
                            }}
                          </td>
                          <td width="33.3%" class="table_header">
                            {{
                              'policies.proposal.add-edit.steppers.previous-policy.type_of_loss'
                                | translate
                            }}
                          </td>
                          <td width="33.3%" class="table_header">
                            {{
                              'policies.proposal.add-edit.steppers.previous-policy.additional_info'
                                | translate
                            }}
                          </td>
                        </tr>

                        <tr>
                          <td width="33.3%" class="table_result">
                            {{
                              dateTimeFormatService.formatDateWithoutTime(
                                cla?.year
                              )
                            }}
                          </td>
                          <td width="33.3%" class="table_result">
                            {{ cla.type_of_loss }}
                          </td>
                          <td width="33.3%" class="table_result">
                            {{ cla?.additional_info || '-' }}
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </tbody>
                </table>
              </ng-container>

              <!------ Driver Stepper -------->
              <ng-container *ngIf="!isMotorFleet">
                <p class="table_caption">
                  <strong>
                    04.
                    {{ 'policies.proposal.add-edit.steppers.5' | translate }}
                  </strong>
                </p>

                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.driver.question_1'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.driver_details?.question_1
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.driver.question_2'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.driver_details?.question_2
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>

                    <tr
                      *ngIf="proposal_information?.driver_details?.question_2"
                    >
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.driver.question_2_details'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.driver_details
                            ?.question_2_details || '-'
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.driver.question_3'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.driver_details?.question_3
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>

              <!------ Discounts Stepper -------->
              <ng-container *ngIf="!isMotorFleet">
                <p class="table_caption">
                  <strong>
                    05.
                    {{ 'policies.proposal.add-edit.steppers.6' | translate }}
                  </strong>
                </p>

                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.discount.question_1'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.discounts_details?.question_1
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        proposal_information?.discounts_details?.question_1
                      "
                    >
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.discount.question_1_value'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.discounts_details
                            ?.question_1_details || '-'
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.discount.question_2'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.discounts_details?.question_2
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p
                  class="table_caption"
                  *ngIf="!proposal_information?.discounts_details?.question_2"
                >
                  <strong>
                    {{ 'policies.proposal.add-edit.steppers.6.1' | translate }}
                  </strong>
                </p>

                <table
                  *ngIf="!proposal_information?.discounts_details?.question_2"
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="20%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.discount.policy_number'
                            | translate
                        }}
                      </th>
                      <th width="20%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.discount.vehicle_number'
                            | translate
                        }}
                      </th>
                      <th width="20%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.discount.vehicle_type'
                            | translate
                        }}
                      </th>
                      <th width="20%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.discount.expiry'
                            | translate
                        }}
                      </th>
                      <th width="20%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.discount.policy_type'
                            | translate
                        }}
                      </th>
                    </tr>

                    <tr
                      *ngFor="
                        let x of proposal_information?.discounts_details
                          ?.otherVehicles
                      "
                    >
                      <td width="20%" class="table_result">
                        {{ x.policy_number }}
                      </td>
                      <td width="20%" class="table_result">
                        {{ x.vehicle_number }}
                      </td>
                      <td width="20%" class="table_result">
                        {{ x.vehicle_type }}
                      </td>
                      <td width="20%" class="table_result">
                        {{ x?.expiry_date || '-' }}
                      </td>
                      <td width="20%" class="table_result">
                        {{ x?.policy_type || '-' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>

              <!------ Additional Covers Stepper -------->
              <ng-container *ngIf="!isMotorFleet">
                <p class="table_caption">
                  <strong>
                    06.
                    {{ 'policies.proposal.add-edit.steppers.7' | translate }}
                  </strong>
                </p>

                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.hd1'
                            | translate
                        }}
                      </th>
                      <th
                        width="50%"
                        class="table_header"
                        style="text-align: right"
                      >
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.hd2'
                            | translate
                        }}
                      </th>
                    </tr>
                    <tr
                      *ngFor="
                        let cover of this.proposal_information
                          ?.additional_covers_details?.covers
                      "
                    >
                      <td width="50%" class="table_result">
                        {{ cover?.name }}
                      </td>
                      <td
                        width="50%"
                        class="table_result"
                        style="text-align: right"
                      >
                        {{ thousandSeparatorAdd(cover?.value) }}
                      </td>
                    </tr>
                    <!-- <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_1'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_1
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_2'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_2
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_3'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_3
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_4'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_4
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        proposal_information?.additional_covers_details
                          ?.question_4
                      "
                    >
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_4_value'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_4_value || '-'
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_5'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_5
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_6'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_6
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        proposal_information?.additional_covers_details
                          ?.question_6
                      "
                    >
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_6_value'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_6_value || '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_7'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_7
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_8'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_8
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        proposal_information?.additional_covers_details
                          ?.question_8
                      "
                    >
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_8_value'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_8_value || '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_9'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_9
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        proposal_information?.additional_covers_details
                          ?.question_9
                      "
                    >
                      <th width="25%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_9_days'
                            | translate
                        }}
                      </th>
                      <td width="25%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_9_days || '-'
                        }}
                      </td>
                      <td width="25%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_9_value'
                            | translate
                        }}
                      </td>
                      <td width="25%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_9_value || '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_10'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_10
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_11'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_11
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_12'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_12
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_13'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_13
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-covers.question_14'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_covers_details
                            ?.question_14
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </ng-container>

              <!------ Commercial Vehicles Stepper -------->
              <!-- <ng-container>
                <p class="table_caption">
                  <strong>
                    08.
                    {{ 'policies.proposal.add-edit.steppers.8' | translate }}
                  </strong>
                </p>

                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.commercial-vehicles.question_1'
                            | translate
                        }}<br /><br />
                        {{
                          'policies.proposal.add-edit.steppers.commercial-vehicles.question_1-part2'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.commercial_vehicles_details
                            ?.question_1
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>

                    <ng-container
                      *ngIf="
                        proposal_information?.commercial_vehicles_details
                          ?.question_1
                      "
                    >
                      <tr>
                        <th width="70%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.commercial-vehicles.question_1_details'
                              | translate
                          }}
                        </th>
                        <td width="30%" class="table_result">
                          {{
                            proposal_information?.commercial_vehicles_details
                              ?.question_1_details || '-'
                          }}
                        </td>
                      </tr>

                      <tr>
                        <th width="70%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.commercial-vehicles.nature_heading-pdf'
                              | translate
                          }}
                        </th>
                        <td width="30%" class="table_result">
                          {{ goods_nature }}
                        </td>
                      </tr>

                      <tr>
                        <th width="70%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.commercial-vehicles.goods_value'
                              | translate
                          }}
                        </th>
                        <td width="30%" class="table_result">
                          {{
                            proposal_information?.commercial_vehicles_details
                              ?.goods_value || '-'
                          }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>

                <ng-container
                  *ngIf="
                    proposal_information?.commercial_vehicles_details
                      ?.question_1
                  "
                >
                  <br />
                  {{
                    'policies.proposal.add-edit.steppers.commercial-vehicles.legal_liability_heading-pdf'
                      | translate
                  }}

                  <table
                    width="100%"
                    border="1"
                    style="border-collapse: collapse; text-align: left"
                  >
                    <tbody>
                      <tr>
                        <th width="70%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.commercial-vehicles.covers_heading-pdf'
                              | translate
                          }}
                        </th>
                        <td width="30%" class="table_result">
                          {{ required_covers }}
                        </td>
                      </tr>
                      <tr>
                        <th width="70%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.commercial-vehicles.legal_liability_value'
                              | translate
                          }}
                        </th>
                        <td width="30%" class="table_result">
                          {{
                            proposal_information?.commercial_vehicles_details
                              ?.legal_liability_value || '-'
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
              </ng-container> -->
              <!------ Additional Information Stepper -------->
              <!-- <ng-container>
                <p class="table_caption">
                  <strong>
                    09.
                    {{ 'policies.proposal.add-edit.steppers.9' | translate }}
                  </strong>
                </p>

                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-information.is_no_claims'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_information_details
                            ?.is_no_claims
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-information.compensation_heading'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{ workmen_compensation }}
                      </td>
                    </tr>

                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-information.is_cover_strike_riot_civil'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_information_details
                            ?.is_cover_strike_riot_civil
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-information.is_cover_strike_riot_civil_terrorism'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_information_details
                            ?.is_cover_strike_riot_civil_terrorism
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <br />
                {{
                  'policies.proposal.add-edit.steppers.additional-information.other_driver_name_heading'
                    | translate
                }}
                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-information.other_driver_name'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          getName(
                            proposal_information?.additional_information_details
                              ?.other_driver_name
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-information.other_driver_dob'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_information_details
                            ?.other_driver_dob
                            ? dateTimeFormatService.formatDateWithoutTime(
                                proposal_information
                                  ?.additional_information_details
                                  ?.other_driver_dob
                              )
                            : '-'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                {{
                  'policies.proposal.add-edit.steppers.additional-information.learner_driver_heading'
                    | translate
                }}
                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="25%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-information.learner_driver_from'
                            | translate
                        }}
                      </th>
                      <td width="25%" class="table_result">
                        {{
                          proposal_information?.additional_information_details
                            ?.learner_driver_from
                            ? dateTimeFormatService.formatDateWithoutTime(
                                proposal_information
                                  ?.additional_information_details
                                  ?.learner_driver_from
                              )
                            : '-'
                        }}
                      </td>
                      <td width="25%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-information.learner_driver_to'
                            | translate
                        }}
                      </td>
                      <td width="25%" class="table_result">
                        {{
                          proposal_information?.additional_information_details
                            ?.learner_driver_to
                            ? dateTimeFormatService.formatDateWithoutTime(
                                proposal_information
                                  ?.additional_information_details
                                  ?.learner_driver_to
                              )
                            : '-'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="70%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.additional-information.is_duty_free'
                            | translate
                        }}
                      </th>
                      <td width="30%" class="table_result">
                        {{
                          proposal_information?.additional_information_details
                            ?.is_duty_free
                            ? 'Yes'
                            : 'No'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container> -->

              <!------ Credit Request Stepper -------->
              <ng-container>
                <p class="table_caption">
                  <strong>
                    {{ isMotorFleet ? '03' : '07' }}.
                    {{ 'policies.proposal.add-edit.steppers.2' | translate }}
                  </strong>
                </p>

                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <tr>
                      <th width="25%" class="table_header">Payment Method</th>
                      <td width="25%" class="table_result">
                        {{
                          titleCasePipe.transform(
                            proposal_information?.credit_request_details
                              ?.payment_method
                          ) || '-'
                        }}
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        proposal_information?.credit_request_details
                          ?.payment_method === 'CREDIT'
                      "
                    >
                      <th width="25%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.credit-request.credit-amount'
                            | translate
                        }}
                      </th>
                      <td width="25%" class="table_result">
                        {{
                          proposal_information?.credit_request_details
                            ?.credit_amount || '-'
                        }}
                        Days
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        proposal_information?.credit_request_details
                          ?.payment_method === 'CREDIT'
                      "
                    >
                      <th width="25%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.credit-request.credit-period'
                            | translate
                        }}
                      </th>
                      <td width="25%" class="table_result">
                        {{
                          proposal_information?.credit_request_details
                            ?.credit_period || '-'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  *ngIf="
                    proposal_information?.credit_request_details?.schedules
                      ?.length > 0
                  "
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                >
                  <tbody>
                    <ng-container
                      *ngFor="
                        let sch of proposal_information?.credit_request_details
                          ?.schedules
                      "
                    >
                      <tr>
                        <td width="33.3%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.credit-request.date'
                              | translate
                          }}
                        </td>
                        <td width="33.3%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.credit-request.days'
                              | translate
                          }}
                        </td>
                        <td width="33.3%" class="table_header">
                          {{
                            'policies.proposal.add-edit.steppers.credit-request.amount'
                              | translate
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td width="33.3%" class="table_result">
                          {{
                            sch.date
                              ? dateTimeFormatService.formatDateWithoutTime(
                                  sch.date
                                )
                              : '-'
                          }}
                        </td>
                        <td width="33.3%" class="table_result">
                          {{ sch.number_of_days }}
                        </td>
                        <td width="33.3%" class="table_result">
                          {{ sch.amount }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
                <table
                  width="100%"
                  border="1"
                  style="border-collapse: collapse; text-align: left"
                  *ngIf="
                    proposal_information?.credit_request_details
                      ?.payment_method === 'CREDIT'
                  "
                >
                  <tbody>
                    <tr>
                      <th width="50%" class="table_header">
                        {{
                          'policies.proposal.add-edit.steppers.credit-request.remark'
                            | translate
                        }}
                      </th>
                      <td width="50%" class="table_result">
                        {{
                          proposal_information?.credit_request_details
                            ?.remark || '-'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </div>
        </div>
        <div style="padding-left: 10px">
          <p class="table_caption">
            <strong>
              {{ isMotorFleet ? '04' : '08' }}.
              {{ 'policies.proposal.pdf.declaration' | translate }}</strong
            >
          </p>
          <div style="margin: auto; padding-top: 10px; text-align: justify">
            I,
            {{ getName(proposal_information?.proposer_details?.name) }}
            {{ 'policies.proposal.pdf.declaration-part1' | translate
            }}<br /><br />

            {{ 'policies.proposal.pdf.declaration-part2-1' | translate }}
            {{ client_information?.name }}
            {{ 'policies.proposal.pdf.declaration-part2-2' | translate }}
          </div>
        </div>
        <br />
        <div>
          <p class="table_caption">
            <strong>
              {{
                'policies.proposal.add-edit.steppers.proposer.name' | translate
              }}:
            </strong>
          </p>
          <table
            width="100%"
            border="1"
            style="border-collapse: collapse; text-align: center"
          >
            <tbody>
              <tr height="80px">
                <th width="50%" class="table_result"></th>
                <td width="50%" class="table_result"></td>
              </tr>
              <tr>
                <td width="50%" class="table_result">
                  {{ 'policies.proposal.pdf.sign' | translate }}
                </td>
                <td width="50%" class="table_result">
                  {{ 'policies.proposal.pdf.date' | translate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <br />
        <div>
          <table
            width="100%"
            border="1"
            style="border-collapse: collapse; text-align: center"
          >
            <tbody>
              <tr height="100px">
                <th width="33.3%" class="table_result"></th>
                <td width="33.3%" class="table_result"></td>
                <td width="33.3%" class="table_result"></td>
              </tr>
              <tr>
                <td width="33.3%" class="table_result">
                  {{ 'policies.proposal.pdf.officer-sign' | translate }}
                </td>
                <td width="33.3%" class="table_result">
                  {{ 'policies.proposal.pdf.date' | translate }}
                </td>
                <td width="33.3%" class="table_result">
                  {{ 'policies.proposal.pdf.rubber-stamp' | translate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="footer"
          style="
            justify-content: center;
            text-align: center;
            margin-left: -10px;
          "
        ></div>
      </div>
    </div>
  </table>
  <div *ngIf="proposal_information?.policy_class === 'MEDICAL'">
    <app-print-medical-proposal-component
      [proposal_information]="proposal_information"
      [ref_number]="ref_number"
      [client_information]="client_information"
      [created_on]="created_on"
      [communication_modes]="communication_modes"
      [communication_languages]="communication_languages"
      [isMedicalGroup]="isMedicalGroup"
      [coverages]="coverages"
      [covers]="covers"
    ></app-print-medical-proposal-component>
  </div>

  <div *ngIf="proposal_information?.policy_class === 'TRAVEL'">
    <app-print-travel-proposal-component
      [proposal_information]="proposal_information"
      [ref_number]="ref_number"
      [client_information]="client_information"
      [created_on]="created_on"
      [communication_modes]="communication_modes"
      [communication_languages]="communication_languages"
      [countries_list]="countries_list"
      [schedule_currency]="schedule_currency"
      [isTravelGroup]="isTravelGroup"
      [plans]="plans"
      [sections]="sections"
      [covers]="covers"
    ></app-print-travel-proposal-component>
  </div>

  <div *ngIf="isMiscClass">
    <app-print-other-proposal-component
      [proposal_information]="proposal_information"
      [ref_number]="ref_number"
      [client_information]="client_information"
      [created_on]="created_on"
      [communication_modes]="communication_modes"
      [communication_languages]="communication_languages"
    ></app-print-other-proposal-component>
  </div>
</div>
